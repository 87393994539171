import React from 'react';
import { Group, CONTAINER_SIZES, TypographyStylesProvider, createStyles } from '@aviationexam/core';
import { SEO } from 'components';

const useStyles = createStyles(theme => ({
  root: {
    width: '100%',
    maxWidth: CONTAINER_SIZES.md,
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
    margin: '100px auto',
    [theme.fn.smallerThan('md')]: {
      marginTop: 60,
      marginBottom: 60,
    },
    [theme.fn.smallerThan('xs')]: {
      marginTop: 40,
      marginBottom: 40,
    },
  },
  typography: {
    '& h1, h2, h3, h4, h5, h6': {
      color: theme.colors.gray[8],
      fontWeight: 600,
    },
    '& h1, h2, h3': {
      fontWeight: 400,
    },
    '& h2': {
      fontSize: 26,
    },
    '& p': {
      textAlign: 'justify',
      color: theme.colors.gray[8],
    },
  },
  fontSizeSm: {
    fontSize: theme.fontSizes.md,
  },
}));

function TermsAndConditionsPage() {
  const { classes } = useStyles();
  return (
    <>
      <SEO title="Terms and Conditions" description="Aviationexam terms of service" lang="en" />
      <TypographyStylesProvider className={classes.typography}>
        <article className={classes.root}>
          <h1>
            <Group direction="column" position="center">
              <div>Terms & Conditions</div>
              <div className={classes.fontSizeSm}>of business company</div>
              <div>Aviationexam s.r.o.,</div>
            </Group>
          </h1>
          <p style={{ textAlign: 'center' }}>
            with registered office at Prague 5 - Stodulky, Kovarova 39/23, Postal code 155 00, the
            Czech Republic, Identification No. 27593886, registered in the Commercial register kept
            by Municipal Court in Prague, Section C, file No. 117629
          </p>
          <h2>I. GENERAL</h2>
          <p>
            These General Terms and Conditions (hereinafter referred to as &quot;
            <strong>GTC</strong>&quot;) govern the relationship between the business company
            Aviationexam s.r.o. (hereinafter referred to as &quot;
            <strong>Seller</strong>&quot;) and customers of the Seller (hereinafter referred to as
            &quot;<strong>Buyer</strong>&quot; or &quot;
            <strong>user</strong>&quot;). The Buyer is obliged to get acquainted with this General
            Terms and Conditions and agree with them before ordering any goods from Buyer.
          </p>
          <p>
            The GTC shall be applied to the entire AVIATIONEXAM.com website, to all of the services
            and products (things) offered and provided by Aviationexam s.r.o. (hereinafter referred
            to jointly as &quot;<strong>goods</strong>&quot;). For avoidance of doubt the Seller
            proclaims that goods shall mean for the purposes of the GTC and the contract also a
            service or a digital content supplied by the Seller to the Buyer (primarily through
            access to the Seller&apos;s databases). By making the purchase of any goods, the Buyer
            agrees with these GTC.
          </p>
          <h2>II. GENERAL INFORMATION</h2>
          <p>
            The Seller shall acknowledge the receipt of the Buyer&apos;s order without undue delay
            by means of an email message.
          </p>
          <p>
            Information about goods and prices presented by the Seller is binding except for obvious
            errors. All displayed prices are final (including VAT where applicable). Other taxes,
            where applicable, such as import duties and the cost of delivery of goods, are not
            included in the prices.
          </p>
          <p>
            The purchase contract may be entered into in English. The purchase contract (including
            GTC) is entered into at the time of receipt of the payment by the Seller from the Buyer.
          </p>
          <p>
            The Seller is entitled to contact the Buyer prior to the shipment of his/her order. This
            includes problems with information received or not received from Buyer&apos;s order (the
            Seller may require additional information) and any issues the Seller discovers related
            to information about goods, availability, or pricing. The Buyer may cancel the order,
            without penalty, prior to its shipping, for a full refund of the purchase price, less
            any bank charges incurred by the Seller.
          </p>
          <p>
            Payment terms are within Seller&apos;s sole discretion, and, unless otherwise agreed,
            payment must be made at the time of purchase. Payment may be made by credit card, wire
            transfer, or some other prearranged payment method. The Buyer is solely responsible for
            entering true, correct and full billing + contact details at the time of placing an
            order, especially with regard to the country of residency and the VAT status (VAT number
            if applicable) of the Buyer. The Seller does not require any fees depending on the
            payment method.
          </p>
          <h2>III. DELIVERY OF THE GOODS</h2>
          <p>
            Payment terms are within Seller&apos;s sole discretion, and, unless otherwise agreed,
            payment must be made at the time of purchase. Payment may be made by credit card, wire
            transfer, or some other prearranged payment method. The Buyer is solely responsible for
            entering true, correct and full billing + contact details at the time of placing an
            order, especially with regard to the country of residency and the VAT status (VAT number
            if applicable) of the Buyer. The Seller does not require any fees depending on the
            payment method.
          </p>
          <p>
            If so requested by the Buyer, the Seller shall confirm in writing the extent and the
            duration of the Buyer&apos;s claims from liability for defects and the manner in which
            the Buyer can exercise his/her rights following from the liability.
          </p>
          <p>
            Shipping and handling fees are based on the size and weight of the order and the
            shipping method which the Buyer chooses for the delivery of the order. Delivery dates
            stated above (time required for delivery) are estimates only. The Seller shall not be
            responsible for delays in deliveries by a shipping carrier as it can not influence these
            factors. For international orders, the Buyer is responsible to pay all taxes, duties,
            and brokerage fees, which are in addition to shipping and handling charges applied to
            the order. The Buyer is solely responsible for correctly stating his/her VAT tax
            domicile for the purpose of VAT (Value Added Tax) application.
          </p>
          <h2>IV. WITHDRAWAL</h2>
          <p>
            The Buyer may withdraw from the contract within 14 days from the takeover of goods or
            the last part of the delivery, regardless of the method of receipt of the goods or
            payment.
          </p>
          <p>
            The Buyer may withdraw from the contract anytime before dispatch of the goods by the
            Seller.
          </p>
          <p>
            The Buyer shall send or hand over the withdrawal from the contract to the Seller at
            latest within 14 days as of the takeover of the goods. The Buyer is not obliged to state
            the reason for the withdrawal from the contract. To facilitate communication, the Buyer
            shall state in the withdrawal from the contract the date of purchase or contract
            number/sales receipt, bank account and the method of returning the goods which the Buyer
            chose.
          </p>
          <p>
            The Seller shall return to the Buyer the amount corresponding to the full price of the
            goods and paid cost of its delivery (hereinafter referred to as &quot;
            <strong>the Respective Amount</strong>&quot;) within 14 days as of the date of delivery
            of withdrawal from the contract (hereinafter referred to as &quot;
            <strong>Return period</strong>
            &quot;). The Respective Amount shall be returned in the same way as the Seller received
            the payment from the Buyer. If the Seller offered several options for certain delivery
            method, the Seller is obliged to reimburse only the cheapest one. The Buyer shall return
            the goods to the Seller at latest within the Return period as well. The goods shall be
            returned to the Seller complete, preferably in the original packaging and it shall not
            show signs of wear or damage. If the value of the goods was decreased through the
            handling or disposition of the goods by the Buyer in a manner which was not necessary
            with regards to the nature and features of the goods, the Seller is entitled to claim a
            compensation for the decrease of the value of the goods and set it off against the
            Respective Amount. The same rule shall be applied if the goods were damaged.
          </p>
          <p>
            The cost of returning the goods shall be borne (in case of the withdrawal) by the Buyer.
            The Seller is not obliged to return to the Buyer the Respective Amount unless the Buyer
            already returned the goods to the Seller.
          </p>
          <p>
            Exceptions: The right to withdraw from the contract shall not be applied to contracts
            for the supply of digital content if it is not supplied on a tangible medium and the
            delivery of the digital content takes place, at the Buyer&apos;s express and informed
            previous request, before the end of the withdrawal period. The Seller proclaims that it
            supplies digital content only without tangible medium and the delivery of the digital
            content before the end of the withdrawal period can take place through AVIATIONEXAM.com
            website only at the Buyer&apos;s express and informed previous request. Therefore if the
            Buyer orders the supply of digital content through AVIATIONEXAM.com website and the
            delivery of the digital content takes place before the end of the withdrawal period, the
            Buyer is not entitled to withdraw from the contract.
          </p>
          <p>
            Online form for withdrawal from the contract is available{' '}
            <a href="https://www.aviationexam.com/Data/UI/CWF.pdf" target="_blank" rel="noreferrer">
              here
            </a>
          </p>
          <p>
            The delivery of the form for withdrawal sent by the Buyer shall be acknowledged by the
            Seller without undue delay by means of an email message or in writing.
          </p>
          <h2>V. LIABILITY FOR DEFECTS</h2>
          <p>
            The Seller shall be responsible for the defects of the goods (e.g. if the goods do not
            have agreed or reasonably expected features, the goods are not complete, the quantity,
            measure, weight do not correspond with legal, contractual or pre-contractual
            conditions).
          </p>
          <ul>
            <li>
              If a defect is regarded as fundamental breach of the contract (no matter if the defect
              is repairable or irreparable) the Buyer may notify the defect and require (according
              to his/her choice)
            </li>
            <li>
              removal of such defects by delivery of new goods without defects or by delivery of
              missing goods; or
            </li>
            <li>removal of such defects by their repair; or</li>
            <li>reasonable reduction of the purchase price, or</li>
            <li>refund of the purchase price on the basis of withdrawal from the contract.</li>
          </ul>
          <p>
            The breach of contract is regarded as fundamental if the party in breach, while entering
            the contract, knew or should have known that the other party would not enter into the
            contract, if he/she would have anticipated the breach of the contract.
          </p>
          <p>
            If a defect is regarded as non-fundamental breach of the contract (no matter if the
            defect is repairable or irreparable) the Buyer is entitled to require removal of the
            defect or reasonable reduction of the purchase price.
          </p>
          <h2>VI. BUYER’S RIGHTS FROM LIABILITY FOR DEFECTS AND WARRANTY</h2>
          <p>
            The Seller is liable for defects occurred after takeover of the goods within 24 month
            warranty period or at the time of usability stated in advertising, on packaging or in
            the attached instructions.
          </p>
          <p>
            The Buyer is entitled to assert claim following from the liability for defects upon the
            Seller at the latest within two years as of the takeover of the goods. The Buyer is
            entitled to require to have the defects repaired free of charge or to obtain reasonable
            reduction of the purchase price; if it is not inappropriate to the nature of the defects
            (especially if it is not possible to repair the defect without undue delay) the Buyer
            may require supply of new goods without defects or a new part without defects.
          </p>
          <p>
            If removable defect occurs repeatedly after the repair (the third complaint for the same
            defect or the fourth complaint for the different defects) or if the goods have more
            defects (at least three defects at the same time), the Buyer is entitled to claim
            reasonable reduction of the purchase price or exchange (replacement) of the goods or
            he/she can withdraw from the contract.
          </p>
          <p>
            In case the repair or replacement of the goods is not possible, the Buyer may require
            full refund of purchase price on the basis of withdrawal from the contract.
          </p>
          <p>
            The Seller is not obliged to meet the Buyer&apos;s claim, if the Seller proves that the
            Buyer has known about the defects before takeover or the defects has been caused by the
            Buyer.
          </p>
          <p>
            The Seller is not liable for defects which occur as a result of wear and tear or failure
            to follow the instructions.
          </p>
          <h2>VII. CLAIM SETTLEMENT</h2>
          <p>
            The Buyer is obliged to send a notice of defects (complaint) to the Seller or to the
            person designated for repair without undue delay after detection of defects. By doing so
            in writing or electronically, the Buyer should state his/her contact information,
            description of the defect and the choice of claims under Section V. and VI. Online form
            is available here.
          </p>
          <p>
            The Buyer is obliged to inform the Seller which claim he/she has chosen when he/she
            notifies the Seller of the defects or without undue delay after sending this notice. The
            change of asserted claim without the Seller&apos;s consent is possible only if the Buyer
            required repair of the defect, which turned out to be irreparable.
          </p>
          <p>
            If the claim following from the fundamental breach of contract is not chosen by the
            Buyer on time, the Buyer shall have only claims following from the non-fundamental
            breach of contract.
          </p>
          <p>
            The Buyer shall prove the purchase of the goods (preferably by the receipt) upon
            notification of defects. The period for settling the complaint is running as of the date
            of delivery of the defective goods to the Seller (or to the place designated for
            repair). The goods shall be packed by the Buyer in such a manner as to preserve and
            protect the goods. The goods shall be clean and complete.
          </p>
          <p>
            The Seller shall immediately, but not later than within three working days, decide on
            the complaint or as the case may be that it is necessary to make an expert opinion. The
            Seller shall notify the Buyer of necessity of the expert opinion. The Seller shall
            settle the complaint, including the removal of defects, without undue delay, at the
            latest within 30 days, unless agreed otherwise with the Buyer. The failure to fulfill
            this duty shall be regarded as fundamental breach of contract.
          </p>
          <p>
            If the Seller refuses to remove the defect, the Buyer is entitled to require reasonable
            reduction of the purchase price or to withdraw from the contract.
          </p>
          <p>
            The warranty period shall be prolonged by the period running as of the notification of
            defects (complaint) to its settlement (or until the time the Buyer was obliged to pick
            up the goods). If the goods or their parts are replaced by the new goods the liability
            for defects of the Seller shall be the same as in case of the new goods or their parts.
          </p>
          <h2>VIII. MISCELLANEOUS</h2>
          <h3>PRIVACY & SECURITY POLICY</h3>
          <h4>Personal Data Security</h4>
          <p>
            The Seller recognizes the importance of protecting Buyer&apos;s privacy. Any
            registration, personal, or other information Buyer shares with the Seller is securely
            managed and safeguarded.
          </p>
          <p>
            To maintain the accuracy of the Personal Data, as well as to prevent unauthorised access
            and ensure the correct use of Personal Data, the Seller has implemented appropriate
            physical, technical, and administrative measures to safeguard and secure the Personal
            Data we collect.
          </p>
          <p>
            For example, the Seller uses Secure Socket Layer (SSL) protocol—an industry standard for
            encryption over the Internet—to protect in transmission the Personal Data collected
            online. All electronic Personal Data that Seller maintains is securely stored and
            further protected through our use of appropriate access controls.
          </p>
          <h4>Disclosure and Transfer of Personal Data</h4>
          <p>
            No personal financial information (for example credit card numbers) are in any way
            handled or stored by the Seller (the Seller do not come into contact with Buyer&apos;s
            credit card information - all payments made through eShop are processed directly by
            contractual banks, not by the Seller).
          </p>
          <p>
            None of the above mentioned information is provided to other parties unless below cases.
          </p>
          <h4>GDPR</h4>
          <p>
            Declaration on the processing of personal data under Regulation (EU) 2016/679 of the
            European Parliament and of the Council on the protection of natural persons with regard
            to the processing of personal data and on the free movement of such data, and repealing
            Directive 95/46/EC (General Data Protection Regulation), (hereinafter referred to as
            &quot;<strong>GDPR</strong>&quot;).
          </p>
          <p>
            This document contains more detailed information related to the processing of your
            personal data within the provision and management of products of the company
            Aviationexam s.r.o, with registered office at Prague 5 - Stodůlky, Kovářova 39/23,
            postcode 15500, company ID number: 27593886, registered in the Commercial Register
            maintained by the Municipal Court in Prague, Section C, Insert 117629, email:
            support@aviationexam.com.
          </p>
          <p>
            The purpose of the document is particularly to inform you what personal data about you
            we will process since 25 May 2018, for what purposes, in what manner and based on what
            legal title we will do so, who else can process your personal data with or without your
            consent, where and how you can get information about your processed personal information
            and what your rights are in the area of personal data protection.
          </p>
          <h5>1. Personal Data Controller</h5>
          <p>
            The company Aviationexam s.r.o, with registered office at Prague 5 - Stodůlky, Kovářova
            39/23, post code 15500, company ID number: 27593886, registered in the Commercial
            Register maintained by the Municipal Court in Prague, Section C, Insert 117629, email:
            support@aviationexam.com (hereinafter referred to as the &quot;
            <strong>Controller</strong>&quot;) hereby informs you, in accordance with Article 12 and
            following GDPR, about the processing of your personal data and about your rights.
          </p>
          <h5>2. Scope and Purpose of the Processing and Legal Basis of the Processing</h5>
          <p>
            We process your personal data for the purpose of concluding a contract, managing a
            contract, or for negotiating or changing a contract carried out on the proposal of the
            data subject. In such a case, personal data are processed only to the extent necessary
            for the conclusion and performance of the contract. These include, in particular,
            products concerning the preparation for tests of EASA-FCL and test of FAA, furthermore
            the sale and offer of products, merchandise, electronic books, textbooks, tests
            available at{' '}
            <a href="https://www.aviationexam.com/" target="_blank" rel="noreferrer">
              aviationexam.com
            </a>{' '}
            and other products.
          </p>
          <p>
            For the above stated purposes, the provision of your personal data is voluntary, however
            it is necessary for the conclusion of the contract, its subsequent administration, where
            the provision of personal data serves here primarily for the unambiguous and
            unmistakeable identification of the data subject.
          </p>
          <p>
            Personal data are processed to the extent to which the relevant data subject has
            provided them to the Controller, this namely in connection with the conclusion of the
            aforementioned contractual legal relationship with the Controller or with the fulfilment
            of the statutory obligations of the Controller, in particular the relevant laws to
            ensure the obligations laid down by the tax and related laws of the Czech Republic as
            amended.
          </p>
          <p>
            The processing of your personal data is also necessary for the purposes of the
            legitimate interests of the Controller or of a third party, except in cases where your
            interests or fundamental rights and freedoms requiring the protection of personal data
            prevail over such interests, especially if the data subject is a child. This may be the
            case for legitimate interests: the debt recovery on the data subject (customer), other
            customer disputes (e.g. reclamation), securing of evidence for the case of necessity to
            defend the rights of the Controller, debtor records and records of the misusing of
            access data or products offered by the Controller.
          </p>
          <p>
            If you have given a voluntary consent, then we process your personal data for marketing
            purposes as well, if we have a granted consent from you. You can revoke your given
            consent at any time, even in its individual parts, in your profile on the website of the
            Controller or by telephone on the phone + 420 270 005 114, in writing by e-mail to
            support@aviationexam.com or at the Controller&apos;s registered office address.
          </p>
          <p>
            The Controller also uses the services of Google Analytics, Google Adwords, Sklik, Bing,
            Quora, Hotjar / Smartlook and Smartsupp for the purposes of marketing or advertising
            targeting. By browsing of these websites, you consent to the use of these services.
          </p>
          <h5>3. Recipients of Personal Data</h5>
          <p>
            In order to ensure the proper operation of the company Aviationexam s.r.o. and be able
            to provide you with quality and proper service, other third parties have access to your
            personal data. These are, in particular, IT service providers (the owner of the servers
            on which the data are stored), law and accounting offices and other persons who provide
            services to Aviationexam s.r.o. and with whom we, of course, have a concluded written
            contract on the processing of personal data in order to protect your personal data.
          </p>
          <p>
            The Controller further states that your personal data may be accessed by the state
            authorities within the framework of fulfilling the legal obligations stated by the
            relevant legal regulations.
          </p>
          <h5>4. Categories of Personal Data That Are the Subject Matter of Processing</h5>
          <ul>
            <li>
              addressing and identification data serving to the unambiguous and unmistakeable
              identification of the data subject (e.g. name, surname, permanent address)
            </li>
            <li>
              data enabling the contact with the data subject (contact details – e.g. phone number
              and e-mail address)
            </li>
            <li>other and descriptive data (registration date and last login date)</li>
            <li>
              operational and location data
              <ul>
                <li>
                  These are data processed for the purposes of fulfilling of the contract (user
                  login, issues with the providing of service etc.).
                </li>
                <li>
                  These are in particular the following data:
                  <ul>
                    <li>date and time of login to the web, or server</li>
                    <li>IP address</li>
                    <li>browser</li>
                    <li>http user agent</li>
                  </ul>
                </li>
                <li>
                  In the case of mobile applications (the system iOS, Mac, Android, Windows):
                  <ul>
                    <li>date of activity</li>
                    <li>type of activity</li>
                    <li>version of the used application</li>
                    <li>type of device</li>
                    <li>version of device operating system</li>
                    <li>IP address</li>
                    <li>DNS</li>
                    <li>
                      UID (unique identifier that serves to verification of the user, device, and
                      application).
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
          <h5>5. Method of Personal Data Processing</h5>
          <p>
            The Controller processes personal data both manually and automatically, and keeps the
            records of all activities, both manual and automated, in which processing of personal
            data occurs.
          </p>
          <h5>6. Time of Personal Data Storing</h5>
          <p>
            We keep your personal information in compliance with legal regulations for the duration
            of the contract so we could provide you with our services. Upon termination of your
            contract or the obligations arising from it or related to it, we have your data stored
            for a period of time that is required and stated in the relevant legal regulations,
            particularly tax and accounting laws, and further in accordance with general limitation
            periods.
          </p>
          <p>
            If you have provided the consent to sending of business and marketing messages, this
            consent is valid for the duration of the use of the products and services of the
            Controller, as well as for the period of the next 1 year after the termination of the
            use of the products or services.
          </p>
          <p>
            If you do not sign into your account for more than one year, so your account will be
            inactive for the mentioned time, all your data will be deleted, unless you have an
            active product under your account or your account is associated to a Learning Management
            System. The obligation under the first paragraph of this point is not affected by this.
          </p>
          <h5>7. Rights of Data Subjects</h5>
          <p>
            You have the right to contact the company Aviationexam s.r.o at any time and request
            information and access to personal data and its processing. Further, you have the right
            to the deletion, to the correction of inaccurate or incomplete personal data, the right
            to limitation of the processing, to objection against the processing, to the information
            on the fact whether automated decision is being made, including profiling, further the
            data portability, and the right to withdraw the given consent. You can apply these
            rights to the Controller contacts stated below. With the eventual complaint, you can
            also turn to the Office for Personal Data Protection. You also have the right to be
            notified by us without undue delay about a personal data breach, if this violation would
            result in a high risk for your rights and freedoms.
          </p>

          <p>
            <a href="/gdpr" target="_blank" rel="noreferrer">
              The detailed description of the data subjects&apos; rights
            </a>
          </p>

          <p>
            If you have any queries or comments about information of personal data processing,
            please do not hesitate to contact us by phone +420 270 005 114 or at the e-mail address
            support@aviationexam.com or directly in writing to the address of the registered office
            of the company Aviationexam s.r.o., Prague 5 - Stodůlky, Kovářova 39/23, postcode 15500.
          </p>

          <h3>REGISTRATION & PASSWORD SHARING</h3>

          <p>
            The Seller reserves the right to refuse supply of goods (services) or subscription to
            any person or entity for any or no reason whatsoever. Registration is valid for a single
            user only. Through the registration process, user chooses a username and password
            (his/her account within our system). The user is responsible for maintaining the
            confidentiality of the username and password, and is also fully responsible for all
            activities that occur under his/her ID or password.
          </p>

          <p>
            The Seller does not allow any of the following:
            <ul>
              <li>any other person sharing username and password;</li>
              <li>
                access through a single username and password being made available to multiple users
                on a network;
              </li>
            </ul>
          </p>

          <p>
            Seller&apos;s servers use advanced functions for detection of password sharing. Any such
            occurrence will automatically be reported to the Seller by the system and user&apos;s
            account may become temporarily blocked for further investigation or cancelled without
            entitlement for refund, or the Seller has the right to limit the number of devices under
            the terms of the APPLICATIONS GTC from three (3) to one (1). Violation of any of the two
            articles above will be grounds for account termination without our obligation towards
            the Buyer for any compensation or reimbursement.
          </p>

          <h3>APPLICATIONS</h3>

          <p>
            User can use his/her account through all software platforms or applications offered by
            the Seller (iPhone/iPad application, OS X application, MS Windows software). User can be
            logged in through the online system or through any application. Buyer can use the
            account for the online system plus a maximum of three (3) devices. This is without
            prejudice to the Seller&apos;s right to reduce the number of devices under REGISTRATION
            & PASSWORD SHARING GTC. Only after logout it is possible to login through other
            application. Synchronization is provided for all platforms. It is required to run the
            synchronization at least once a month in order to revalidate the subscription.
          </p>

          <h3>ACCEPTABLE USE POLICY</h3>

          <p>
            The Seller has expended substantial time, effort, and funds to create this website and
            the goods offered. The website{' '}
            <a href="https://www.aviationexam.com" target="_blank" rel="noreferrer">
              aviationexam.com
            </a>{' '}
            is fully copyright protected. All material and all of the images located on this website
            and any materials / products sent to the user by e-mail or delivered in any other form
            from Aviationexam s.r.o. (hereinafter referred to as the &quot;content&quot;) or in any
            way relating to the website belong to the Seller. The user may retrieve and display
            content from the website on a computer screen, print a single copy of individual pages
            on paper and store such pages for caching purposes only, all for your personal and
            non-commercial use only.
          </p>

          <p>
            The user (Buyer) MAY NOT do any of the following without prior written permission from
            the Seller:
            <ul>
              <li>
                reproduce, modify, commercially exploit, redistribute, mass-download, reuse,
                reproduce, repost, sell, publish or otherwise circulate any of the content, system
                or parts of the content or system (including using it as part of any library,
                archive or similar service)
              </li>
              <li>
                remove copyright or trade marks from any material or copies of content made or
                obtained in accordance with these GTC
              </li>
              <li>
                create a database in electronic or structured format by systematically downloading
                and storing the content or parts of the content
              </li>
              <li>use the content on any other website or other networked computer environment</li>
            </ul>
          </p>

          <p>
            By accessing the Website,{' '}
            <a href="https://www.aviationexam.com" target="_blank" rel="noreferrer">
              aviationexam.com
            </a>
            , user agrees not to:
            <ul>
              <li>
                damage, modify, interfere with or disrupt access to the website or do anything which
                may interrupt or impair its functionality;
              </li>
              <li>
                make any commercial or business use of the website and its content or resell or
                commercially benefit from any part or aspect of the website;
              </li>
              <li>
                publish, post, distribute, or otherwise transmit defamatory, offensive, infringing,
                obscene, indecent or other unlawful or objectionable material or information;
              </li>
            </ul>
          </p>

          <p>
            The Seller is entitled to prevent the user from accessing the website, without prejudice
            to any of Seller&apos;s accrued rights, if the user is contravening this Acceptable Use
            Policy or any other term or condition of these GTC. If Buyer&apos;s access privileges
            are terminated on the basis of this paragraph, the Seller will hold no obligation for
            compensation for any of user&apos;s claims.
          </p>

          <h3>LIABILITIES</h3>

          <p>
            The Seller takes all such steps as are reasonably necessary to provide a fast and
            reliable service, but exclude to the fullest extent permitted by law any liability for
            the security of the services on the website or for any disruption of the website however
            caused, loss of or corruption of any material in transit, or loss of or corruption of
            material when downloaded onto any computer system.
          </p>

          <p>
            All of the goods (content) offered through the website Aviationexam.com or by the Seller
            has been thoroughly proof-read, examined and checked for inaccuracies and errors.
            However mistakes can happen. If you believe you have found an incorrectly presented
            data, please, let us know by e-mail at: support@aviationexam.com and we will address the
            issue promptly.
          </p>

          <h3>LINKED SITES</h3>

          <p>
            Certain links are provided that may lead to websites maintained by third parties over
            which the Seller has no control. The Seller takes no responsibility for the accuracy,
            content, or any aspect of that material, and disclaims any liability to the user for
            such material or for any consequence of user&apos;s decision to use the links provided
            or use of such material.
          </p>

          <h3>TERMINATION</h3>

          <p>
            The Seller is entitled to terminate user&apos;s access to the website and/or other
            products or services without any prior notice to the user where (by way of example and
            without limitation):
            <ul>
              <li>
                there is a regulatory or statutory change limiting Seller&apos;s ability to provide
                a website; or
              </li>
              <li>
                user is abusing the services (goods) provided or are otherwise acting in breach of
                these GTC.
              </li>
            </ul>
          </p>

          <h3>Organizations / Learning Management System SECTION</h3>

          <p>
            Any organization using the Aviationexam system, can upload it&apos;s own study material
            or it&apos;s specific question bank (material) into the system. This material is visible
            only to users assigned under respective organization account (FTO/ATO/school students).
            The Seller does not claim any ownership rights to such material nor he is responsible
            for its content (in terms of copyright issues). The organization is fully responsible
            for the content of uploaded material (in terms of copyright issues).
          </p>

          <p>
            The Seller stores test results and study plans of students for at least seven (7) years.
            In case of termination of cooperation, the Seller enables the organization access to the
            results of the organization&apos;s students for the next three (3) years.
          </p>

          <p>
            The organization is responsible for the security of the data exported from the
            Seller&apos;s platform howsoever (e.g. printed report, electronic document,
            electronically exported data to another system).
          </p>

          <p>
            An organization that buys accesses from the Seller, regardless of form (activation codes
            or slots), is authorized to publicly offer for sale (e.g. on the e-shop, social
            networks, internet, printed or electronic price lists) and resell these accesses only in
            the lengths of subscriptions that are available to regular (end) customers.
          </p>

          <h3>CONTACT</h3>

          <p>
            If you have any questions concerning any part of these GTC please contact us by email
            at: support@aviationexam.com
          </p>

          <h2>IX. CLOSING PROVISIONS</h2>

          <p>
            All disputes arising from the contract entered into between the Seller and the Buyer
            (user) and/or in connection with it shall be decided by the Czech courts. The court
            having local (venue) jurisdiction shall be the Municipal court in Prague (if the
            regional court has the subject matter jurisdiction) or the District court in Prague 1
            (if the district court has the subject matter jurisdiction).
          </p>

          <p>
            Compliance with the duties following from the Act No. 634/1992 Coll., on Consumer
            Protection, as amended, is checked and supervision is performed by the Czech Trade
            Inspection (
            <a href="https://www.coi.cz" target="_blank" rel="noreferrer">
              coi.cz
            </a>
            ).
          </p>

          <p>
            These General Terms and Conditions, including its components are valid and effective as
            of 1 January 2014. GTC, including its components, are available at the registered office
            premises of business company Aviationexam s.r.o. or electronically at{' '}
            <a href="https://www.aviationexam.com" target="_blank" rel="noreferrer">
              aviationexam.com
            </a>
            .
          </p>

          <p>
            These General Terms and Conditions are set out in accordance with and are governed by
            Act No. 89/2012 Coll. Civil Code, and Act. No. 634/1992 Coll. on Consumer Protection, as
            amended.
          </p>
        </article>
      </TypographyStylesProvider>
    </>
  );
}

export default TermsAndConditionsPage;
